import React from 'react'

type Props = {}

function Uninstall({}: Props) {
  return (
    <div className="policy-wrapper">
      <p
        style={{
          marginTop: "0cm",
          marginRight: "0cm",
          marginBottom: "0cm",
          marginLeft: "0cm",
          lineHeight: "normal",
          fontSize: 15,
          fontFamily: '"Calibri",sans-serif',
          background: "white"
        }}>
        <span
          style={{
            fontSize: 13,
            fontFamily: '"Arial",sans-serif',
            color: "#3C4043"
          }}>
          1) Click on the three vertical dots icon in the upper right-hand
          corner of your Chrome browser.
        </span>
      </p>
      <p
        style={{
          marginTop: "0cm",
          marginRight: "0cm",
          marginBottom: "0cm",
          marginLeft: "0cm",
          lineHeight: "normal",
          fontSize: 15,
          fontFamily: '"Calibri",sans-serif',
          background: "white"
        }}>
        <span
          style={{
            fontSize: 13,
            fontFamily: '"Arial",sans-serif',
            color: "#3C4043"
          }}>
          2) Go to "Settings.”
        </span>
      </p>
      <p
        style={{
          marginTop: "0cm",
          marginRight: "0cm",
          marginBottom: "0cm",
          marginLeft: "0cm",
          lineHeight: "normal",
          fontSize: 15,
          fontFamily: '"Calibri",sans-serif',
          background: "white"
        }}>
        <span
          style={{
            fontSize: 13,
            fontFamily: '"Arial",sans-serif',
            color: "#3C4043"
          }}>
          3) In the left menu that appears, click on "Extensions.”
        </span>
      </p>
      <p
        style={{
          marginTop: "0cm",
          marginRight: "0cm",
          marginBottom: "0cm",
          marginLeft: "0cm",
          lineHeight: "normal",
          fontSize: 15,
          fontFamily: '"Calibri",sans-serif',
          background: "white"
        }}>
        <span
          style={{
            fontSize: 13,
            fontFamily: '"Arial",sans-serif',
            color: "#3C4043"
          }}>
          4) From the list of installed Extensions, locate the extension you
          would like to uninstall.
        </span>
      </p>
      <br />
    </div>
  )
}

export default Uninstall