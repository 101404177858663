import React from 'react'
import { Link } from "react-router-dom";
import './footer.styles.scss'

type Props = {}

const Footer = (props: Props) => {
  return (
    <footer id="dpFooter">
          <div className="copyRight">© 2022 All Rights Reserved.</div>
          <div className="footerMenu">
            <ul>
              <li><Link to={'/'}>Main</Link></li>
              <li><Link to={'/privacy'}>Privacy Policy</Link></li>
              <li><Link to={'/terms'}>Terms of Use</Link></li>
              <li><Link to={'/about'}>About Us</Link></li>
              <li><Link to={'/contact'}>Contact</Link></li>
              <li><Link to={'/cookies'}>Cookies</Link></li>
            </ul>
          </div>
        </footer>
  )
}

export default Footer