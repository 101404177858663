import React from 'react'
import { company as c } from "../../config/main";

type Props = {}

const Contact = (props: Props) => {
  return (
    <div id="mainContent" className="policy-wrapper">
      <div className="configpolicy">
        <p>
          <strong>Contact Us</strong>
        </p>
        <p className="p1">&nbsp;</p>
        <p className="p1">
          In case you have any questions or concerns you may contact us by email
          at:&nbsp; <a href={`mailto:${c.email}`}>{c.email}</a>{" "}
          or at the following address:
        </p>
        <p className="p1">
          {c.name} - {c.address}
        </p>
      </div>
    </div>
  )
}

export default Contact