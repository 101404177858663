import React from 'react';
import { company as c } from '../../config/main';

type Props = {};

const Terms = (props: Props) => {
  return (
    <div id="mainContent" className="policy-wrapper">
      <div className="policy">
        <p className="p1">
          <strong>Terms of Service and License Agreement</strong>
        </p>
        <p className="p1">
          <strong>TERMS AND CONDITIONS OF SERVICE</strong>
        </p>
        <p className="p1">
          PLEASE READ THE FOLLOWING TERMS AND CONDITIONS OF SERVICE (THE&nbsp;“
          <strong>TERMS</strong>”&nbsp;OR&nbsp;“<strong>AGREEMENT</strong>”)
          CAREFULLY BEFORE USING AND ITS PRODUCTS, INCLUDING, WITHOUT
          LIMITATION, ANY TEXT, DESCRIPTIONS, PRODUCTS, WEBSITE, SOFTWARE,
          APPLICATION, GRAPHICS, PHOTOS, SOUNDS, VIDEOS, INTERACTIVE FEATURES,
          SERVICES AND ANY OTHER CONTENT AVAILABLE THEREON OR THERETO, NOW OR IN
          THE FUTURE, (COLLECTIVELY, AND TOGETHER WITH THE SITE, THE&nbsp;“
          <strong>SERVICE</strong>”) SO THAT YOU ARE AWARE OF YOUR LEGAL RIGHTS
          AND OBLIGATIONS WITH RESPECT TO SERVICE AND {c.name} (“
          <strong>{c.name}</strong>”,&nbsp;“<strong>WE</strong>
          ”&nbsp;OR&nbsp;“<strong>OUR</strong>”). We reserve the right, at our
          discretion, to modify these Terms. We will inform you of any such
          modifications by posting the latest changes on the Site. Your
          continued use of the Services following the posting of such changes
          will mean your acceptance of those changes.
        </p>
        <p className="p1">
          <strong>GENERAL TERMS OF SERVICE</strong>
        </p>
        <p className="p1">
          By visiting and accessing and using the Service, including without
          limitation any of the content thereon, you consent to these Terms and
          any subsequent changes to these Terms and acknowledges that you have
          read and understood our &nbsp;privacy policy. In addition, certain
          content or features of the Service may be subject to additional terms
          or rules which will be posted in connection with such content or
          features; all such terms and rules are incorporated into these Terms.
          If you do not agree, you may not use the Service including the
          applicable content or feature. Your right to use the Service is
          expressly conditioned upon your assent to all of these Terms, to the
          exclusion of all other terms. The Service is not intended for minors
          in any jurisdiction and you hereby certify that if you are an
          individual, you are of legal age to form a binding contract. If you
          are accessing or using the Service on behalf of an entity, you
          represent and warrant that you have authority to bind the entity to
          these Terms. You also certify that you are legally permitted to access
          and use the Service, and take full responsibility for such access and
          use. You are solely responsible for complying with all laws, rules and
          regulations applicable to your access and use of the Service.
          {c.name} may, in its sole discretion, refuse to offer the Service to
          any person or entity and change its eligibility criteria at any time.
        </p>
        <p className="p1">
          <strong>SERVICE USE LICENCE AND ACCESS</strong>
        </p>
        <p className="p1">
          {c.name} hereby grants you a personal, limited, non-exclusive,
          non-sublicensable, non-transferable, revocable license to access and
          use the Service subject and in accordance with these Terms and
          provided that you comply with and refrain from the following; &nbsp;no
          licenses or rights are granted to you by implication or otherwise,
          except for the licenses and rights expressly granted to you herein.
        </p>
        <ul className="ul1">
          <li className="li1">
            Copy, modify, distribute, transmit, publicly display, publicly
            perform or create derivative works of any portion of the Service;
          </li>
          <li className="li1">
            Circumvent, disable or otherwise interfere with security-related
            features of the Service or features that prevent or restrict use or
            copying of any part of the Service;
          </li>
          <li className="li1">
            Frame, hyper-link, or otherwise interfere with or in any manner
            disrupt, circumvent, overburden or compromise any part of the
            Service;
          </li>
          <li className="li1">
            Automate access to&nbsp;the Service, including, without limitation,
            through the use of APIs, bots, scrapers or&nbsp;other similar
            devices or mechanisms.
          </li>
          <li className="li1">
            Use the Service (including, but not limited to, any features or
            systems provided by or available through the Service) or any content
            thereon to promote, conduct, or contribute to activities that in
            {c.name}’s sole discretion are fraudulent, obscene, pornographic,
            commercial, inappropriate or illegal, or which promotes hatred
            towards any group of people;
          </li>
          <li className="li1">
            Disrupt servers or networks connected to the Service or interfere
            with the access, use or enjoyment of this Service by others,
            including, but not limited to, causing greater demand on the Service
            than is deemed reasonable by {c.name};
          </li>
          <li className="li1">
            Use any method to intercept or expropriate any system data or
            information from the Service;
          </li>
          <li className="li1">
            Use any robot, spider, other automatic device, or manual process to
            crawl, index, or copy the Service or the content contained herein;
          </li>
          <li className="li1">
            Decompile, disassemble, reverse engineer or otherwise attempt to
            discover any source code or underlying ideas or algorithms of the
            Service;
          </li>
          <li className="li1">
            Attempt to gain unauthorized access to the Service, computer systems
            or networks;
          </li>
          <li className="li1">
            Link, upload, post, email, transmit or otherwise make available any
            content that (a) you do not have a right to make available under any
            law, or under any contractual or fiduciary relationships, (b)
            infringes any patent, trademark, trade secret, copyright or other
            proprietary rights of any party;
          </li>
          <li className="li1">
            Collect or harvest any personally identifiable information from the
            Service;
          </li>
          <li className="li1">
            Use the communication systems provided by the Service for any
            commercial solicitation purposes, or link, upload, post, email,
            transmit or otherwise make available any unsolicited or unauthorized
            advertising, promotional materials, “junk mail,” “spam,” “chain
            letters,” “pyramid schemes,” or any other form of solicitation in
            connection with the Service;
          </li>
          <li className="li1">
            Link, upload, post, email, transmit or otherwise make available any
            material that contains software viruses or any other computer code,
            viruses, malware, bots, files or programs designed to interrupt,
            damage, disable, overburden, impair, destroy or limit the
            functionality of any computer software or hardware or
            telecommunications equipment;
          </li>
          <li className="li1">
            Interfere with, damage, disable, overburden, impair, destroy, limit
            the functionality of or disrupt the Service or servers or networks
            connected to the Service, or disobey any requirements, procedures,
            policies of networks connected to the Service;
          </li>
          <li className="li1">
            Violate any applicable local, state, national or international law,
            rule or regulation, or harm {c.name} or any other person or entity;
            or
          </li>
          <li className="li1">
            Allow or facilitate any third party to do any of the foregoing.
          </li>
        </ul>
        <p className="p1">
          {c.name} will have the right to terminate your access to the Service,
          in its sole discretion, immediately and with or without cause.{' '}
          {c.name} may, in its sole discretion and at any time, in any way, for
          any reason, change or discontinue any part of the Service. Without
          derogating from the above, you are solely responsible and liable for
          any activity by any person who uses the Service on your behalf. You
          agree to immediately notify {c.name} of any unauthorized use suspected
          by you or any other breach of security known or suspected by you.
        </p>
        <p className="p1">
          <strong>ACCESS TO&nbsp;SERVICES; AVAILABILITY OF THE SERVICES</strong>
        </p>
        <p className="p1">
          You are responsible for obtaining and maintaining any equipment and
          ancillary services needed to&nbsp;connect to&nbsp;or&nbsp;access
          or&nbsp;otherwise use the Service, including, without limitation,
          modems, hardware, software, internet service and telecommunications
          capacity. You shall be&nbsp;solely responsible for ensuring that such
          equipment and ancillary services are compatible with the Service.
        </p>
        <p className="p1">
          The Service may be interrupted, including for maintenance, repairs,
          upgrades, or network or equipment failures. We may discontinue some or
          all of our Service, including certain features and the support for
          certain devices and platforms, at any time. Events beyond our control
          may affect our Service, such as events in nature and other force
          majeure events.
        </p>
        <p className="p1">
          <strong>Mobile Services</strong>
        </p>
        <p className="p1">
          The Service may be provided or made available via a&nbsp;mobile device
          through the download and installation of an application on a mobile
          device (collectively, “<strong>Mobile Services</strong>”). To&nbsp;the
          extent you access the Service through a&nbsp;mobile device, your
          wireless service carrier’s standard charges, data rates and other fees
          may apply. In&nbsp;addition, downloading, installing or&nbsp;using the
          Mobile Services may be&nbsp;prohibited or&nbsp;restricted by&nbsp;your
          carrier, and not all Mobile Services may work with all carriers
          or&nbsp;devices.
        </p>
        <p className="p1">
          <strong>INTELLECTUAL PROPERTY RIGHTS</strong>
        </p>
        <p className="p1">
          As between {c.name} and you, {c.name} is the sole owner of the
          Service, including without limitation, all applicable U.S. and
          non-U.S. copyrights, patents, trademarks, and trade secrets, and other
          intellectual property rights in connection thereto. {c.name}
          further owns all rights to its logos and trademarks used in connection
          with the Service. All other logos and trademarks appearing on or
          through the Service are the property of their respective owners.
          {c.name} reserves all rights not expressly granted in and to the
          Service. You acknowledge that you do not acquire any ownership rights
          to the Service. You will not delete or in any manner alter the
          copyright, trademark, and other proprietary rights notices or markings
          appearing on or through the Service.
        </p>
        <p className="p1">
          <strong>DISCLAIMER OF WARRANTIES</strong>
        </p>
        <p className="p1">
          THE SERVICE IS PROVIDED “AS IS” AND “AS AVAILABLE”, WITHOUT WARRANTY
          OF ANY KIND, EITHER EXPRESS OR IMPLIED INCLUDING, BUT NOT LIMITED TO
          WARRANTIES OF TITLE, NON-INFRINGEMENT, CONDITIONS OF MERCHANTABILITY
          OR FITNESS FOR A PARTICULAR PURPOSE AND THOSE ARISING BY STATUTE OR
          FROM A COURSE OF DEALING OR USAGE OF TRADE. YOU EXPRESSLY AGREE THAT
          YOUR USE OF THE SERVICE IS AT YOUR SOLE RISK AND RESPONSIBILITY.
          NEITHER {c.name} NOR ITS RESPECTIVE EMPLOYEES, AGENTS, THIRD PARTY
          CONTENT PROVIDERS OR LICENSORS WARRANT THAT THE SERVICE WILL BE
          UNINTERRUPTED OR ERROR FREE; NOR DO THEY MAKE ANY WARRANTY AS TO THE
          RESULTS THAT MAY BE OBTAINED FROM USE OF THE SERVICE, OR AS TO THE
          ACCURACY, TIMELINESS, COMPLETENESS, OR ADEQUACY OF ANY INFORMATION,
          PRODUCT OR SERVICE PROVIDED THERETO. FURTHERMORE, {c.name} DOES NOT
          WARRANT, ENDORSE OR GUARANTEE ANY CONTENT, PRODUCT, OR SERVICE THAT IS
          FEATURED OR ADVERTISED ON THE SERVICE. WHILE WE MAKE REASONABLE
          EFFORTS TO ENSURE THAT THE SERVICE WILL FUNCTION AS CLAIMED, {c.name}{' '}
          DOES NOT GUARANTEE THAT THE SERVICE WILL BE FREE OF BUGS, SECURITY
          BREACHES, VIRUS ATTACKS AND THE LIKE, OR THAT IT WILL ALWAYS BE
          AVAILABLE. THE SERVICE WILL OCCASIONALLY BE UNAVAILABLE FOR ROUTINE
          MAINTENANCE, UPGRADING, OR OTHER REASONS. IN ADDITION, YOU AGREE THAT{' '}
          {c.name} WILL NOT BE HELD RESPONSIBLE FOR ANY CONSEQUENCES TO ITS
          USERS, INCLUDING THOSE THAT MAY RESULT FROM TECHNICAL PROBLEMS OF THE
          INTERNET, SLOW CONNECTIONS, TRAFFIC CONGESTION OR OVERLOAD OF OUR OR
          OTHER SERVERS, ETC. THIS DISCLAIMER OF LIABILITY APPLIES TO ANY
          DAMAGES OR INJURY CAUSED BY THE SERVICE, INCLUDING WITHOUT LIMITATION
          AS A RESULT OF ANY FAILURE OF PERFORMANCE, ERROR, OMISSION,
          INTERRUPTION, DELETION, DEFECT, DELAY IN OPERATION OR TRANSMISSION,
          COMPUTER VIRUS, COMMUNICATION LINE FAILURE, THEFT OR DESTRUCTION OR
          UNAUTHORIZED ACCESS TO, ALTERATION OF, OR USE OF ANY DATA OR
          INFORMATION, WHETHER FOR BREACH OF CONTRACT, TORT, NEGLIGENCE, OR
          UNDER ANY OTHER CAUSE OF ACTION.
        </p>
        <p className="p1">
          <strong>LIMITATION OF LIABILITY</strong>
        </p>
        <p className="p1">
          IN NO EVENT WILL {c.name}, ITS OFFICERS, DIRECTORS, EMPLOYEES,
          PARENTS, AFFILIATES, SUCCESSORS, ASSIGNS, THIRD PARTY CONTENT
          PROVIDERS AND LICENSORS BE LIABLE TO ANY PARTY FOR ANY INDIRECT,
          DIRECT, SPECIAL, PUNITIVE, INCIDENTAL OR CONSEQUENTIAL DAMAGES
          (INCLUDING, BUT NOT LIMITED TO, DAMAGES FOR LOSS OF BUSINESS PROFITS,
          BUSINESS INTERRUPTION, LOSS OF PROGRAMS OR INFORMATION, HARM TO
          GOODWILL OR REPUTATION), OR ANY OTHER DAMAGES ARISING IN ANY WAY OUT
          OF THE AVAILABILITY, USE, RELIANCE ON, OR INABILITY TO USE THE SERVICE
          EVEN IF {c.name} SHALL HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH
          DAMAGES, AND REGARDLESS OF THE FORM OF ACTION, WHETHER IN CONTRACT,
          TORT, OR OTHERWISE. IN ANY EVENT AND WITHOUT LIMITING THE GENERALITY
          OF THIS SECTION TO THE EXTENT PERMITTED BY LAW, YOU AGREE THAT{' '}
          {c.name}’S TOTAL LIABILITY TO YOU FOR ALL DAMAGES AND LOSSES FOR
          ACCESSING AND USING THE SERVICE SHALL NOT EXCEED THE AGGREGATE AMOUNT
          PAID BY YOU TO {c.name}, IF ANY, IN THE ONE (1) MONTH IMMEDIATELY
          PRECEDING THE EVENT GIVING RISE TO THE CLAIM. BECAUSE SOME STATES OR
          JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY
          FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, IN SUCH STATES OR
          JURISDICTIONS, {c.name}’S LIABILITY (INCLUDING ITS OFFICERS,
          DIRECTORS, EMPLOYEES, PARENTS, AFFILIATES, SUCCESSORS, ASSIGNS, THIRD
          PARTY CONTENT PROVIDERS AND LICENSORS) SHALL BE LIMITED TO THE EXTENT
          PERMITTED BY LAW. YOU AND {c.name} AGREE THAT ANY CAUSE OF ACTION
          ARISING OUT OF OR RELATED TO THE SERVICE MUST COMMENCE WITHIN ONE (1)
          YEAR AFTER THE CAUSE OF ACTION ACCRUES. OTHERWISE, SUCH CAUSE OF
          ACTION IS PERMANENTLY BARRED.
        </p>
        <p className="p1">
          <strong>INDEMNIFICATION</strong>
        </p>
        <p className="p1">
          By agreeing to use the Service, you agree to defend, indemnify and
          hold {c.name}, its affiliates and successors, and their respective
          owners, officers, directors, employees and agents harmless from and
          against any and all claims, damages, obligations, losses, liabilities,
          costs and expenses, including but not limited to reasonable attorneys’
          fees, arising in any way from (i) your use of the Service; (ii) your
          violation of these Terms; or (iii) your violation of any third party
          right, including without limitation any intellectual property,
          publicity or privacy right.
        </p>
        <p className="p1">
          <strong>LINKED SITES AND APPLICATIONS</strong>
        </p>
        <p className="p1">
          The Service may contain features that may link you to third parties’
          applications and/or web sites (“
          <strong>Linked Applications &amp; Sites</strong>”). The Linked
          Applications &amp; Sites are not reviewed, owned, controlled or
          examined by {c.name} in any way. {c.name} has no control over those
          Linked Applications &amp; Sites, and assumes no responsibility for the
          content, privacy policies, or practices of any of those Linked
          Applications &amp; Sites, or any additional links contained therein.
          In addition, {c.name} will not and cannot censor or edit the content
          of any Linked Applications &amp; Sites. The offering of these features
          does not imply {c.name}’s endorsement of, or association with, the
          Linked Applications &amp; Sites and by using the Service, you
          expressly release {c.name} from any and all liability arising from
          your use of any Linked Applications &amp; Sites. It is your sole
          responsibility to comply with the appropriate terms of service of the
          Linked Applications &amp; Sites as well as with any other obligation
          under copyright, secrecy, defamation, decency, privacy, security and
          export laws related to the use of such Linked Applications &amp; Sites
          and any content contained thereon. We encourage you to read the terms
          and conditions, term of use and privacy policy of the Linked
          Applications &amp; Sites. In no event shall {c.name} be liable to
          anyone for any damage arising from or occasioned by the creation or
          use of the Linked Applications &amp; Sites or the information or
          material accessed-through these Linked Applications &amp; Sites. All
          title and intellectual property rights in and to the content of any
          third party web site which may be linked to or viewed in connection
          with this Service is the property of the respective content owner and
          may be protected by applicable copyright or other intellectual
          property laws and treaties. This Agreement grants you no rights to use
          such content except as allowed by such third party.
        </p>
        <p className="p1">
          <strong>ASSIGNMENT</strong>
        </p>
        <p className="p1">
          {c.name} may transfer, assign sublicense or pledge in any manner
          whatsoever to a third party, any of its rights and obligations under
          this Agreement, without notifying you or receiving your consent. You
          shall not transfer, assign, sublicense nor pledge in any manner
          whatsoever, any of your rights or obligations under this Agreement.
        </p>
        <p className="p1">
          <strong>APPLICABLE LAWS / JURISDICTION</strong>
        </p>
        <p className="p1">
          You agree that the laws of {c.country}, excluding its conflicts-of-law
          rules, shall govern these Terms. Please note that your use of the
          Service may be subject to other local, state, national, and
          international laws. You expressly agree that exclusive jurisdiction
          for any claim or dispute with {c.name} or relating in any way to your
          use of the Service resides in the courts of {c.country}. You further
          agree and expressly consent to the exercise of personal jurisdiction
          in the courts of {c.country}, in connection with any dispute or claim
          involving {c.name} or its affiliates, subsidiaries, employees,
          contractors, officers, directors, telecommunication providers and
          content providers.
        </p>
        <p className="p1">
          <strong>MISCELLANEOUS</strong>
        </p>
        <p className="p1">
          These Terms constitute the entire agreement of the parties with
          respect to the subject matter hereof, and supersede all previous
          written or oral agreements between the parties with respect to such
          subject matter. The provisions of these Terms are for the benefit of
          {c.name}, its parent, subsidiaries, other affiliates and its third
          party content providers and licensors and each shall have the right to
          assert and enforce such provisions directly or on its own behalf. No
          waiver by either party of any breach or default hereunder shall be
          deemed to be a waiver of any preceding or subsequent breach or
          default. The section headings used herein are for convenience only and
          shall not be given any legal import
        </p>
      </div>
    </div>
  );
};

export default Terms;
