export const company = {
    name: 'Insoft LLC',
    email: 'office@marketinyo.com',
    address: 'Georgia, City Tbilisi, Nadzaladevi District, G. Chkondideli str., N22, Apt. 88',
    country: 'Georgia',
    link: '#',
    mainTitle: 'Over 100+ of the best car and motorcycle wallpaper images with our extension!',
    mainSubTitle: 'Enjoy our wallpapers',
    // Bing
    // mainDescription: 'By clicking you accept and agree to install our extension, set the Chrome New Tab to The Extension Homepage, and to the Terms of Use and Privacy Policy. Please note that the extension will change your New Tab provider to Bing.',
    // Yahoo
    mainDescription: 'By clicking \'Add To Chrome\' you accept and agree to install our extension, set the Chrome™ New Tab Search to Yahoo , and to the Terms of Use and Privacy Policy. Please note that the extension will change your New Tab provider to Yahoo.',
}