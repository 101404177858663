import React from 'react';

type Props = {};

const About = (props: Props) => {
  return (
    <div id="mainContent" className="policy-wrapper">
      <div className="policy">
        <p>
          <strong>About Us</strong>
        </p>
        <p>&nbsp;</p>
        <p className="p1">
          We are a team of web experts creating tools for web developer and end
          users for over 10 years now.
        </p>
        <p className="p1">
          We have created news extension for you to stay connected and get news
          and recent updates about major news events right from your browser at
          all times.
        </p>
      </div>
    </div>
  );
};

export default About;
