import React from 'react';

type Props = {};

const Cookies = (props: Props) => {
  return (
    <div id="mainContent" className="policy-wrapper">
      <div className="policy">
        <p className="p1">
          <b>Cookie Policy</b>
        </p>
        <p className="p2">
          Cookies enable us to identify your device. We set temporary (known as
          session cookies) and permanent (known as persistent cookies) cookies
          for a number of reasons, including to enhance the functionality of our
          website by storing your preferences and personalizing your experience.
          We also set cookies to provide us with analytical information to
          improve the performance of our website to provide you with a better
          user experience.
        </p>
        <p className="p3" />
        <p className="p2">
          We don’t sell the information collected by cookies, nor do we disclose
          the information to third parties, except as set forth in our Privacy
          Policy. In our Privacy Policy, we explain in more detail how we and
          third parties set cookies from our website and for what purpose.
        </p>
        <p className="p3" />
        <p className="p2">
          If you wish to control what cookies are set on your device through our
          website, please read the description below about cookies.
        </p>
        <p className="p3" />
        <p className="p2">
          <b>Web browser cookies</b>
        </p>
        <p className="p2">
          If you don’t want to receive cookies, you can modify your browser so
          that it notifies you when cookies are sent to it or you can refuse
          cookies altogether. You can also delete cookies that have already been
          set.
        </p>
        <p className="p3" />
        <p className="p2">
          If you wish to restrict or block web browser cookies which are set on
          your device then you can do this through your browser settings; the
          Help function within your browser should tell you how. Alternatively,
          you may wish to visit www.aboutcookies.org, which contains
          comprehensive information on how to do this on a wide variety of
          desktop browsers.
        </p>
        <p className="p3" />
        <p className="p2">
          Our website does work without cookies, but you will lose some features
          and functionality if you choose to disable cookies.
        </p>
        <p className="p3" />
        <p className="p2">
          <b>Do Not Track (DNT) browser setting</b>
        </p>
        <p className="p2">
          DNT is a feature offered by some browsers which, when enabled, sends a
          signal to websites to request that your browsing is not tracked, such
          as by third party ad networks, social networks and analytic companies.
          We currently do not respond to DNT requests, however, you may opt-out
          of tracking on our website by deleting your web browser cookies.
        </p>
      </div>
    </div>
  );
};

export default Cookies;
