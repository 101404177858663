import React from 'react';
import { company as c } from '../../config/main';
import './privacy.styles.scss';

type Props = {};

const Privacy = (props: Props) => {
  return (
    <div id="mainContent" className="policy-wrapper">
      <div className="policy">
        <p className="p1">Privacy Policy</p>
        <p className="p1">
          This privacy statement (“<strong>Privacy Policy</strong>”) explains
          the information, collection and use policies of {c.name} and any of
          its affiliated companies ("<strong>Company</strong>", “
          <strong>we</strong>” or “<strong>us</strong>”) with respect to the
          websites, services, products, technology, software and/or software
          components (collectively, the "
          <strong>Software &amp; Services</strong>")of Company that you install,
          use, access or interact with ("<strong>use</strong>"), but excludes
          software and/or services that have separate privacy policies that do
          not incorporate this privacy policy.
        </p>
        <p className="p1">
          This Privacy Policy meant to explain, in as clear and plain language
          as possible, how we collect and use information. You will also find an
          explanation of certain rights you have&nbsp;&nbsp;in respect of your
          Personal Information.
        </p>
        <p className="p1">
          Please read this Privacy Policy carefully, before using our Website,
          so you can fully understand our practices in relation to Personal
          Information, before we begin processing your information.
        </p>
        <p className="p1">
          <strong>Information Company Collects and Receives.</strong>
        </p>
        <p className="p1">
          When you Use the Software &amp; Services, we automatically collect and
          store the following types of information:
        </p>
        <ul className="ul1">
          <li className="li1">
            Technical Information.&nbsp;Certain technical information regarding
            your device, and your Use of the Software &amp; Services and other
            third party software and applications installed on your device (the
            “<strong>Other Software</strong>”), including the device type and
            version number, the type, name and version of operating system and
            Other Software including, without limitation, their interactions
            with the Software &amp; Services, ISP (Internet Service Provider),
            hardware version, location, Internet protocol (IP) address,
            anonymous user identifiers such as cookies and similar web
            technologies, device configuration, version of the Software &amp;
            Services, and your configuration and settings of the device and the
            Software &amp; Services, including language and time zone;
          </li>
          <li className="li1">
            Usage Information.&nbsp;Certain usage related information regarding
            your use and interaction with your device, including the Software
            &amp; Services and Other Software, such as when and how you Use the
            Software &amp; Services and Other Software, how you use your
            internet browser and internet search related applications, your
            language settings, the web pages you visit, applications you use and
            the content you see, access and utilize on such web pages and
            applications; for example, offerings and advertisements that you
            view, use and access, how you use them and your response to them
            (ie. clickstream data), how frequently you use them, your search
            queries and the non-precise location, time and date of your
            searches;
          </li>
          <li className="li1">
            Support Related Information.&nbsp;If you communicate with us via our
            support section or via email, we may also collect your email
            address, name and any information voluntarily submitted by you in
            connection with such support (for example, your name, company name,
            and particular support query or request).
          </li>
        </ul>
        <p className="p1">
          Except as described in this Privacy Policy, it is not our intention to
          personally identify or contact you through the collection of data
          described above (except for registration and user support purposes as
          set forth in this Privacy Policy). However, the data collected may
          include personally identifiable information or personal data as such
          terms are defined under applicable laws (“<strong>PII</strong>” or “
          <strong>Personal Information</strong>”) as a necessary part of using
          the Software &amp; Services. For example, search terms that you enter
          when using search services as part of the Software &amp; Services may
          include PII. In addition, IP address and other online identifiers such
          as cookies and similar web technologies are automatically transferred
          to us when you visit a publisher website that uses the Software &amp;
          Services. Not being provided with such information, will affect our
          ability to provide you with certain elements of the Software &amp;
          Services.
        </p>
        <p className="p1">
          <strong>How Company Uses Your Information.</strong>
        </p>
        <p className="p1">Use of PII</p>
        <p className="p1">
          As stated above, we collect the following information from you:
        </p>
        <ul className="ul1">
          <li className="li1">IP Address;</li>
          <li className="li1">
            Install-ID (for installations of specific software such as Browser
            Extensions);
          </li>
          <li className="li1">Cookie Id;</li>
          <li className="li1">URL Referrer;</li>
          <li className="li1">Search terms; and</li>
          <li className="li1">Support Related Information.</li>
        </ul>
        <p className="p1">
          We consider this information to be PII, meaning that without this
          information, we would not reasonably be able to identify you. Company
          processes this information on the basis that it is a data controller
          of the PII. This is how we use this information:
        </p>
        <ul className="ul1">
          <li className="li1">
            To provide you with the search services and functionality, including
            providing you with the search results of our web search partners in
            response to your online search queries. We delete this data once our
            fraud and lifetime value assessment has been completed (as discussed
            in the next paragraphs). For this purpose, Company processes this
            information on the basis of a legitimate interest of an individual’s
            right to freedom of expression, including the freedom to hold
            opinions and to receive and impart information and ideas.
          </li>
        </ul>
        <p className="p1">
          We will share search term and your IP address with Microsoft, our
          search partner who provides you with certain aspects of the search
          services and functionality, including the results of your search
          queries. To learn more about information collected by Verizon Media,
          please click&nbsp;here. To learn more about information collected by
          Microsoft, please click&nbsp;here. You can opt-out of Microsoft's
          personalized advertising services by following the instructions
          found&nbsp;here.
        </p>
        <ul className="ul1">
          <li className="li1">
            To detect and prevent fraud on its Software &amp; Services.
          </li>
        </ul>
        <p className="p1">
          Company needs the combination of IP Address, Cookie Id, Install-ID,
          URL and Search Term to conduct fraud detection on potentially
          fraudulent and illegal uses of the Software &amp; Services. We need
          this information for 3 months to conduct this fraud analysis.
          Following this 3 month period, we will delete all data except for the
          Cookie-ID and Install-ID which we use for business performance which
          we discuss in the next paragraph. For this purpose, Company processes
          this information on the basis of a legitimate interest for preventing
          fraud.
        </p>
        <p className="p1">
          In performing fraud detection and prevention, we use the services of
          third parties who will receive and access your PII.
        </p>
        <ul className="ul1">
          <li className="li1">To assess the lifetime value of a user.</li>
        </ul>
        <p className="p1">
          In our experience, business performance in search is measured over a
          24 month period, including, the lifetime value of a users that use our
          Software &amp; Services. For that purpose, Company retains the
          Cookie-ID and Install-ID and the number of search queries performed
          (but not the actual search query itself) performed by each ID for a
          period of 24 months from the initial collection. For this purpose,
          Company processes this information on the basis of a legitimate
          interest for its business operations and internal due diligence.
        </p>
        <ul className="ul1">
          <li className="li1">Customer Support.</li>
        </ul>
        <p className="p1">
          We also use Support Related Information you provide to us to provide
          you with support and handle your complaints and feedback. This
          information will not be connected with any other information collected
          from you on or through the Software &amp; Services, including, any
          individual ID generated or collected by us, unless you ask as to. For
          this purpose, Company processes this information on the basis of a
          legitimate interest and performance of a contract with you.
        </p>
        <p className="p1">
          We will retain this information for the time it takes to provide you
          with the support you requested and to handle your complaints. We may
          keep your information for longer periods in case of disputes and other
          legitimate reasons described in the next section “Disputes and
          Enforcement; Applicable law, regulations or Enforceable Governmental
          Request”.
        </p>
        <ul className="ul1">
          <li className="li1">
            Disputes and Enforcement; Applicable law, regulations or Enforceable
            Governmental Request.
          </li>
        </ul>
        <p className="p1">
          We use your PII and Support Related Information to take action in case
          of a dispute involving you in connection with the Software &amp;
          Services and to enforce the terms and conditions applicable to the
          Software &amp; Services. We also use your PII to comply with legal and
          regulatory requirements (e.g., the disclosure of information to law
          enforcement or tax authorities), and to protect against suspicious or
          other illegal activities.
        </p>
        <p className="p1">
          For this purpose, we may share your information with law enforcement
          or other competent authorities and any third party, as may be required
          (e.g to respond to claims or satisfy any legal process, subpoena, or
          governmental request; or to protect the rights, property, or personal
          safety of Company’s users, its partners, or the general public). We
          will also share this information with our professional advisors in the
          scope of this purpose.
        </p>
        <p className="p1">
          We will retain this Personal Information for the amount of time as
          required by law to fulfill the above purposes. This may include
          ensuring that we have an accurate record of your dealings in the event
          of any complaints or challenges, or if we reasonably believe there is
          a prospect of litigation relating to your PII or dealings.
        </p>
        <p className="p1">Use of Non-PII</p>
        <p className="p1">
          Company uses non-PII information it collects for the following
          purposes:
        </p>
        <ul className="ul1">
          <li className="li1">
            To operate and update the Software &amp; Services, including in
            connection with Software &amp; Services provided to our business
            partners, and to provide you with existing and new functionality and
            features of the Software &amp; Services.
          </li>
          <li className="li1">
            to improve, fix and customize the Software &amp; Services;
          </li>
          <li className="li1">
            to monitor, analyze and optimize your use of the Software &amp;
            Services, including analyzing seasonal and annual usage trends and
            providing certain technical and usage information to our business
            partners as part of the services we provide; and
          </li>
          <li className="li1">
            to maintain and improve the quality and operation of the Software
            &amp; Services, including, monitoring viewability of and interaction
            with advertisements, search results and other products and services
            provided by Company.
          </li>
        </ul>
        <p className="p1">
          We retain this information for as long as necessary to fulfill our
          business and/or operational purposes or comply with a legal request.
          We may also choose to anonymize parts of your PII so that it can no
          longer be identifiable or attributable to any person if we want to
          keep it for longer periods of time than the retention periods set
          forth above.
        </p>
        <p className="p1">
          <strong>Additional Sharing of PII with Third Parties.</strong>
        </p>
        <p className="p1">
          We may also share your information in the following events:
        </p>
        <ul className="ul1">
          <li className="li1">
            We may also share your information with our subsidiaries, affiliated
            and parent companies pursuant to the legitimate interest for the
            provision of the Software &amp; Services to you but their use of
            such information must comply with this Privacy Policy.
          </li>
          <li className="li1">
            In the event Company goes through a business transition such as a
            merger or acquisition by another company, consolidation, change of
            control, reorganization, or sale of all or a portion of its assets,
            your information will be among the assets transferred;
          </li>
        </ul>
        <p className="p1">
          With respect to sharing of your PII, we take appropriate measures to
          minimize the PII that we disclose to the third parties, and we require
          these third parties (i) to keep the data secured, and (ii) to use the
          data solely in compliance with this Privacy Policy. In any case other
          than the above, we will not share your PII with third parties without
          your prior consent.
        </p>
        <p className="p1">
          <strong>
            Transferring Personal Information From and Outside of the European
            Union.
          </strong>
        </p>
        <p className="p1">
          We will take appropriate steps to ensure that transfers of your PII
          are in accordance with applicable laws and carefully managed to
          protect your privacy rights and interests. To this end, if we as the
          data controller share your information with our corporate affiliates
          or third party service providers outside the EEA, we have - prior to
          sharing your information with such corporate affiliate or third party
          service provider – established the necessary means to ensure an
          adequate level of data protection. This may be an adequacy decision of
          the European Commission confirming an adequate level of data
          protection in the respective non-EEA country or an agreement on the
          basis of the EU Model Clauses (a set of clauses issued by the European
          Commission) or EU - US Privacy Shield certification. We will provide
          further information on the means to ensure an adequate level of data
          protection on request.
        </p>
        <p className="p1">
          <strong>Third-Party Content.</strong>
        </p>
        <p className="p1">
          Certain Software &amp; Services may include content which may be in
          the form of apps, buttons, gadgets, advertisements, websites, products
          and services (including search services and advertisements) provided
          to you by third parties (collectively, "
          <strong>Third Party Content and Services</strong>"). The use of such
          Third Party Content and Services is subject to their privacy policies.
          In order to clarify, we do not review, approve, monitor, endorse,
          warrant, or make any representations with respect to such Third Party
          Content and Services and your access to any Third Party Content and
          Services is at your own risk. We are not responsible for the
          information contained and/or made available through, the practices of,
          or your use or inability to use such Third Party Content and Services.
          You expressly relieve us from any and all liability arising from your
          use of such Third Party Content and Services. We recommended that you
          read the terms, conditions and privacy policies of such Third Party
          Content and Services before using or accessing any of them.
        </p>
        <p className="p1">
          <strong>Cookies and Other Technologies.</strong>
        </p>
        <p className="p1">
          As indicated above, the Software &amp; Services collects or allows
          third parties to collect, through blocks of code called “pixels” or
          “web beacons”, certain information about you and send it to our
          servers and to our partners (either directly or indirectly). We or our
          partners, may package all or part of this information into small text
          files called Cookies and send the Cookie to your computer. As an
          example, Cookies may store search queries made through the Software
          &amp; Services, the web pages you accessed, your interaction with
          advertisements presented to you via the Software &amp; Services,
          features you used, your session duration and your configurations and
          preferences of the Software &amp; Services.
        </p>
        <p className="p1">
          Most browsers are initially set up to accept Cookies but you can reset
          your browser to refuse all Cookies or to indicate when a Cookie is
          sent. However, some functions of the Software &amp; Services may not
          function properly if your cookies are disabled.
        </p>
        <p className="p1">
          <strong>Minors.</strong>
        </p>
        <p className="p1">
          The Software &amp; Services are not intended for minors in any
          jurisdiction and you hereby represent that you are not a minor
          according to you relevant jurisdiction. For example, users under the
          age of 13 in the United States and users under the age of 16 in the
          European Union are not permitted to use the Software &amp; Services.
          We do not knowingly collect, use, share or disclose any personal
          information from minors. We reserve the right to request proof of age
          at any stage, so that we can verify that minors are not using the
          Software &amp; Services.
        </p>
        <p className="p1">
          We advise parents and guardians to regularly monitor their children's
          use of email and other online communications and activities. Control
          tools that create a safer online environment for children are
          available from third party online services and software providers.
        </p>
        <p className="p1">
          In the event that it comes to our knowledge that a minor is using the
          Software &amp; Services, we will prohibit and block such a user from
          accessing the Software &amp; Services and will make all efforts to
          promptly delete any PII with regard to such user, as applicable.
        </p>
        <p className="p1">
          If you are a parent or guardian who has discovered that your child has
          submitted his or her PII without your consent, please contact us as
          provided in this Privacy Policy and we will take the necessary steps
          to remove that information from our database.
        </p>
        <p className="p1">
          <strong>Your Rights.</strong>
        </p>
        <p className="p1">
          The following rights (which may be subject to certain exemptions or
          derogations) shall apply to certain individuals (some of which only
          apply to individuals protected by the GDPR or the CCPA):
        </p>
        <p className="p1">
          <strong>GDPR Rights:</strong>
        </p>
        <ul className="ul1">
          <li className="li1">
            You have a right to access personal data held about you. Your right
            of access may normally be exercised free of charge, however we
            reserve the right to charge an appropriate administrative fee where
            permitted by applicable law;
          </li>
          <li className="li1">
            You have the right to request that we rectify any personal data we
            hold that is inaccurate or misleading;
          </li>
          <li className="li1">
            You have the right to request the erasure/deletion of your personal
            data (e.g. from our records). Please note that there may be
            circumstances in which we are required to retain your personal data,
            for example for the establishment, exercise or defense of legal
            claims;
          </li>
          <li className="li1">
            You have the right to object, to or to request restriction, of the
            processing;
          </li>
          <li className="li1">
            You have the right to data portability. This means that you may have
            the right to receive your personal data in a structured, commonly
            used and machine-readable format, and that you have the right to
            transmit that data to another controller;
          </li>
          <li className="li1">You have the right to object to profiling;</li>
          <li className="li1">
            You have the right to withdraw your consent at any time. Please note
            that there may be circumstances in which we are entitled to continue
            processing your data, in particular if the processing is required to
            meet our legal and regulatory obligations. Also, please note that
            the withdrawal of consent shall not affect the lawfulness of
            processing based on consent before its withdrawal;
          </li>
          <li className="li1">
            You also have a right to request certain details of the basis on
            which your personal data is transferred outside the European
            Economic Area, but data transfer agreements and/or other details may
            need to be partially redacted for reasons of commercial
            confidentiality;
          </li>
          <li className="li1">
            You have a right to lodge a complaint with your local data
            protection supervisory authority (i.e., your place of habitual
            residence, place or work or place of alleged infringement) at any
            time or before the relevant institutions in your place of residence.
            We ask that you please attempt to resolve any issues with us before
            you contact your local supervisory authority and/or relevant
            institution.
          </li>
        </ul>
        <p className="p1">
          <strong>CCPA Rights:</strong>
        </p>
        <ul className="ul1">
          <li className="li1">
            You have the right to know what personal information is being
            collected about you in the twelve months preceding your request;
          </li>
          <li className="li1">
            You have the right to request the erasure/deletion of your personal
            data (e.g. from our records and the records of our service
            providers). Please note that there may be circumstances in which we
            are required to retain your personal data, for example for the
            establishment, exercise or defense of legal claims;
          </li>
          <li className="li1">
            You have the right to know whether your personal information is sold
            or disclosed and to whom in the twelve months preceding your
            request;
          </li>
          <li className="li1">
            You have the right to say no to the sale of your personal
            information;
          </li>
          <li className="li1">
            You have the right to equal service and price, even if you exercise
            your privacy rights;
          </li>
          <li className="li1">
            You have a right to lodge a complaint with your local data
            protection supervisory authority (i.e., your place of habitual
            residence, place or work or place of alleged infringement) at any
            time or before the relevant institutions in your place of residence
            (e.g. the Attorney General in your State). We ask that you please
            attempt to resolve any issues with us before you contact your local
            supervisory authority and/or relevant institution.
          </li>
        </ul>
        <p className="p1">
          Exercising your Rights. You can exercise your rights by contacting us
          as provided under “Contact Us” below. Subject to legal and other
          permissible considerations, we will make every reasonable effort to
          honor your request promptly in accordance with applicable law or
          inform you if we require further information in order to fulfill your
          request. When processing your request, we may ask you for additional
          information to confirm or verify your identity and for security
          purposes, before processing and/or honoring your request. We reserve
          the right to charge a fee where permitted by law, for instance if your
          request is manifestly unfounded or excessive. In the event that your
          request would adversely affect the rights and freedoms of others (for
          example, would impact the duty of confidentiality we owe to others) or
          if we are legally entitled to deal with your request in a different
          way than initial requested, we will address your request to the
          maximum extent possible, all in accordance with applicable law.
        </p>
        <p className="p1">
          <strong>How we Protect your Information.</strong>
        </p>
        <p className="p1">
          We have implemented and will maintain appropriate technical and
          organizational security measures, policies and procedures designed to
          reduce the risk of accidental destruction or loss, or the unauthorized
          disclosure or access to such information appropriate to the nature of
          the information concerned. Note that, although we take measures to
          secure the information we collect and reduce the risk of unwanted
          disclosure, we cannot guarantee that the information will not be
          exposed as a result of unauthorized penetration to our servers.
          Nevertheless, we make commercially reasonable efforts to make the
          collection and security of such information consistent with this
          Privacy Policy and all applicable laws and regulations. As the
          security of information depends in part on the security of the
          computer or device you use to communicate with us and the security you
          use to protect your user IDs and passwords, please make sure to take
          appropriate measures to protect this information.
        </p>
        <p className="p1">
          <strong>Do Not Track.</strong>
        </p>
        <p className="p1">
          Do Not Track is a privacy preference that can be configured in certain
          web browsers (the "<strong>DNT Feature</strong>"); the DNT Feature,
          when enabled on a web browser, signals the websites you visit that you
          do not want certain information about your visit collected. Company
          does not currently respond or recognize DNT Feature signals.
        </p>
        <p className="p1">
          <strong>Complaints.</strong>
        </p>
        <p className="p1">
          If you have any complaints on our processing of your PII or if you
          believe that we have breached our own privacy policies or applicable
          law, we encourage you to contact us. However, please note that
          applicable EU data protection laws provide you with a right to lodge a
          complaint with the competent supervisory authority as well.
        </p>
        <p className="p1">
          <strong>Changes to This Privacy Policy.</strong>
        </p>
        <p className="p1">
          We may, from time to time, change this Privacy Policy. We encourage
          you to visit and review this Policy frequently to stay informed. Prior
          to any material change to this Privacy Policy, Company will present
          you with a detailed notification (e.g. a pop-up notification or other
          reasonable means) of such change including information on how you may
          uninstall the Software &amp; Services (to the extent applicable).
          Other changes, including amendments made to the Policy to comply with
          legal requirements, will become effective immediately upon their
          initial posting. Company will also update the "Last updated” date at
          the end of this Privacy Policy. Your continued use of the Software
          &amp; Services after any change takes effect, will indicate your
          acceptance of the amended Privacy Policy. If you do not agree with any
          of the amended terms, you agree that you shall cease any further use
          of the Software &amp; Services.
        </p>
        <p className="p1">
          <strong>Search Services.</strong>
        </p>
        <p className="p1">
          Some of the web search services provided herein are provided by
          CodeFuel and powered by CodeFuel’s search partners. For information on
          the web search services data collection, please visit{' '}
          <a
            target="_blank"
            href="https://www.codefuel.com/legal/end_user_privacy_policy"
          >
            https://www.codefuel.com/legal/end_user_privacy_policy
          </a>
          , and the search provider's privacy policy, as applicable, both as
          updated from time to time and at any successor locations.
        </p>
        <p className="p1">
          <strong>How to Contact Company.</strong>
        </p>
        <p className="p1">
          If you have any concerns or questions with respect to any matter
          covered by this Privacy Policy, you may contact us at{' '}
          <a href={`mailto:${c.email}`}>{c.email}</a> or at the following
          address:
        </p>
        <p className="p1">
          {c.name} - {c.address}
        </p>
        <p className="p1">
          Last updated:{' '}
          {new Date().toLocaleString('default', { month: 'long' })}, 2022
        </p>
        <p className="p2">&nbsp;</p>
      </div>
    </div>
  );
};

export default Privacy;
